import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PaymentReminder = ({ onClose, onCompletePayment }) => {
  const authData = useSelector((state) => state.Auth.authData);
  const { t } = useTranslation();
  const paymentPending = !authData?.paymentStatus && authData?.type === 3;

  return (
    authData &&
    paymentPending && (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1000px",
          position: "fixed",
          top: 65,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2
        }}>
        <Alert
          severity='warning'
          action={
            <Box>
              <Button color='inherit' size='large' onClick={onCompletePayment} sx={{ mr: 2 }}>
                {t("button.completeBtnText")}
              </Button>
              <IconButton aria-label='close' color='inherit' size='small' onClick={onClose}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Box>
          }>
          <Typography variant='body2'>{t("err.pendingPayment")}</Typography>
        </Alert>
      </Box>
    )
  );
};

export default PaymentReminder;
