import { dispatch } from "reducers/configureStore";
import { authResponseHandler, catchErrorHandler, errorMessageHandler } from "./helpers";
import { setAuthData } from "reducers/slices/AuthSLice";
import axios from "axios";
import { successToast } from "shared/Toast";

const authToken = localStorage.getItem("authToken");

export const getUserData = async () => {
  if (authToken) {
    try {
      const { data } = await axios.get("api/users/user_details");
      if (data?.status) {
        dispatch(setAuthData(authResponseHandler(data.data)));
      } else {
        errorMessageHandler(data);
      }
    } catch (error) {
      catchErrorHandler(error, "getUserData");
    }
  }
};

export const createPaymentOrder = async (payload) => {
  try {
    const { data } = await axios.post("/api/viva-wallet/create_payment_order", payload);
    if (data?.status) {
      if (data.url) {
        window.location.href = data.url;
      }
      successToast(data.message);
      return data.data;
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    catchErrorHandler(error, "createPaymentOrder");
  }
};
