import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./slices/AuthSLice";
import AlertsSlice from "./slices/AlertsSlice";
import MasterDataSlice from "./slices/MasterDataSlice";

const store = configureStore({
  reducer: combineReducers({
    Auth: AuthSlice,
    Alerts: AlertsSlice,
    MasterData: MasterDataSlice
  })
});

export const { dispatch, getState } = store;

export default store;
