export const THEMES = {
  LIGHT: "light",
  DARK: "dark"
};
export const lightTheme = (theme) => theme.palette.mode === "light";
export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

export const getXRayedOptions = (t) => [
  { label: t("filters.xRayedOptions.option1"), value: "yes" },
  { label: t("filters.xRayedOptions.option2"), value: "no" },
  { label: t("filters.xRayedOptions.option3"), value: "e-beam" }
];

export const getSortOptions = (t) => [
  { label: t("filters.sortOptions.option1"), column_name: "price", direction: "asc" },
  { label: t("filters.sortOptions.option2"), column_name: "price", direction: "desc" },
  { label: t("filters.sortOptions.option3"), column_name: "thc", direction: "asc" },
  { label: t("filters.sortOptions.option4"), column_name: "thc", direction: "desc" },
  { label: t("filters.sortOptions.option5"), column_name: "cbd", direction: "asc" },
  { label: t("filters.sortOptions.option6"), column_name: "cbd", direction: "desc" }
];

export const getNavItems = (t) => [
  { item: t("headerNavigation.home"), link: "/" },
  { item: t("headerNavigation.findFlower"), link: "https://www.cannabis-aerzte.de/" },
  { item: t("headerNavigation.newsLetter"), link: "/" }
];

export const recrationDominanceEnums = [
  { title: "Indica Dominant", value: "indica_dominant" },
  { title: "Sativa Dominant", value: "sativa_dominant" },
  { title: "Hybrid", value: "hybrid" }
];

export const cbdLevelOptions_enums = [
  { title: "Gering", value: "low" },
  { title: "Mittel", value: "moderate" },
  { title: "Hoch", value: "high" }
];

export const xRayedOptions = [
  { title: "Ja", value: "yes" },
  { title: "Nein", value: "no" },
  { title: "E-Träger", value: "e-beam" }
];

export const best_time_to_consume_enum = [
  { title: "Morgen", value: "morning" },
  { title: "Abends", value: "evening" },
  { title: "Mittags", value: "midday" },
  { title: "Nachmittags", value: "afternoon" }
];

export const paymentMethod = [
  { title: "Viva Wallet", subTitle: "Credit Card", type: "VIVAWALLET", imgUrl: "assets/payments/vivawallet.png" },
  { title: "GoCardLess", subTitle: "SEPA Direct Debit", type: "GOCARDLESS", imgUrl: "assets/payments/gocardless.png" }
];

export const SubscriptionStatus = {
  TRIAL: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  CANCEL: 4
};
export const consumerExperience_enums = [
  { title: "Anfänger", value: "beginner" },
  { title: "Fortgeschritten", value: "advanced" },
  { title: "Experte", value: "expert" }
];

export const inventory_cultivation_type_enum = [
  { title: "Innen", value: "indoor" },
  { title: "Im Freien", value: "outdoor" },
  { title: "Gewächshaus", value: "green_house" }
];

export const gender_type_enum = [
  { title: "Auto Feminisiert", value: "auto_feminized" },
  { title: "Feminized", value: "feminized" },
  { title: "Regelmäßig", value: "regular" }
];

export const insured_enums = [
  { title: "Ja", value: 1 },
  { title: "Nein", value: 0 }
];
