import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import routes from "./routes";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { getUserData } from "utils/APIs";
import axiosInstance from "utils/axios";
import "./i18next";
axiosInstance.initialize();

const App = () => {
  const router = createBrowserRouter(routes());

  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes
  });

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
        <Toaster position='bottom-left' />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
