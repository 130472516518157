import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import PaymentReminder from "./alert/PaymentReminder";

const AuthGuard = () => {
  const authToken = localStorage.getItem("authToken");
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCompletePayment = () => {
    setOpenAlert(false);
    navigate("/pricing");
  };

  useEffect(() => {
    const isPaymentPage = location?.pathname?.includes("checkout") || location?.pathname?.includes("pricing");
    setOpenAlert(!isPaymentPage);
  }, [location]);

  if (!authToken) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {openAlert && <PaymentReminder onCompletePayment={handleCompletePayment} onClose={() => setOpenAlert(false)} />}
      <Outlet />
    </>
  );
};

export default AuthGuard;
