import axios from "axios";
import { getState } from "reducers/configureStore";
import { errorToast } from "shared/Toast";

export const ArrayFrom = (size) => Array.from(Array(size).keys());

export const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push("Something went wrong!");
  }
  errorArray.forEach((errorMessage, index) => {
    errorToast(errorMessage);
  });
};

export const catchErrorHandler = (error, title) => {
  if (!axios.isCancel(error)) {
    console.log(`error ${title ?? ""}`, error);
  }
};

export const downloadBlobData = (data, fileName) => {
  try {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    // Clean up after the download
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    catchErrorHandler(error);
  }
};

export const authResponseHandler = (responseData) => {
  return {
    ...responseData,
    id: responseData.id,
    firstName: responseData.first_name ?? "",
    lastName: responseData.last_name ?? "",
    email: responseData.email ?? "",
    phone: responseData.phone_number ?? "",
    dob: responseData.date_of_birth ?? "",
    city: responseData.city
      ? {
          id: responseData.city.id,
          label: responseData.city.name
        }
      : null,
    country: responseData.country
      ? {
          id: responseData.country.id,
          label: responseData.country.name,
          shortCode: responseData.country.iso3
        }
      : null,
    state: responseData.state
      ? {
          id: responseData.state.id,
          label: responseData.state.name
        }
      : null,
    postalCode: responseData.postal_code ?? "",
    paymentStatus: responseData.payment_status
  };
};

export const formateHistoryData = (historyData) => {
  const allIllnesses = getState().MasterData.illnessData;
  let illness = JSON.parse(historyData.illness);
  if (illness.length) {
    illness = illness.map((id) => {
      const illness = allIllnesses?.find((a) => a.id === id);
      return illness;
    });
  }
  return {
    cbdLevels: historyData.cbd_levels,
    illnesses: illness,
    aromatics: JSON.parse(historyData.aromatic_ids),
    consumeTime: JSON.parse(historyData.best_time_to_consume),
    id: historyData.id
  };
};
