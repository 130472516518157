import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  illnessData: null,
  aromaticsData: null,
  countries: [],
  states: [],
  cities: [],
  expertsData: null,
  manufacturerData: [],
  effectsData: null,
  terpenesData: null,
  traitsData: null,
  settingsData: null,
  geneticsData: [],
  bestTimeToConsumeData: [],
  pznData: [],
  cannabinoidsData: [],
  businessUsers: [],
  strainsData: []
};

const MasterData = createSlice({
  name: "MasterDataSLice",
  initialState,
  reducers: {
    setIllnessData: (state, action) => {
      state.illnessData = action.payload;
    },
    setAromaticsData: (state, action) => {
      state.aromaticsData = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setExpertsData: (state, action) => {
      state.expertsData = action.payload;
    },
    setManufacturerData: (state, action) => {
      state.manufacturerData = action.payload;
    },
    setEffectData: (state, action) => {
      state.effectsData = action.payload;
    },
    setTerpenesData: (state, action) => {
      state.terpenesData = action.payload;
    },
    setTraitsData: (state, action) => {
      state.traitsData = action.payload;
    },
    setSettingsData: (state, action) => {
      state.settingsData = action.payload;
    },
    setGeneticsData: (state, action) => {
      state.geneticsData = action.payload;
    },
    setBestTimeToConsumeData: (state, action) => {
      state.bestTimeToConsumeData = action.payload;
    },
    setPznData: (state, action) => {
      state.pznData = action.payload;
    },
    setCannabinoidsData: (state, action) => {
      state.cannabinoidsData = action.payload;
    },
    setBusinessUsers: (state, action) => {
      state.businessUsers = action.payload;
    },
    setStrainsData: (state, action) => {
      state.strainsData = action.payload;
    },
    clearAllMasterData: () => initialState
  }
});

export const {
  setIllnessData,
  setAromaticsData,
  setCountries,
  setStates,
  setCities,
  setExpertsData,
  setManufacturerData,
  setEffectData,
  setTerpenesData,
  setTraitsData,
  setSettingsData,
  setGeneticsData,
  setBestTimeToConsumeData,
  setPznData,
  setCannabinoidsData,
  setBusinessUsers,
  setStrainsData,
  clearAllMasterData
} = MasterData.actions;

export default MasterData.reducer;
