import React, { Suspense } from "react";
import HashLoaderBackdrop from "./HashLoaderBackdrop";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<HashLoaderBackdrop />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
