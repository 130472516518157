import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authData: null,
  authPrompt: false
};

const User = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action.payload;
    },
    setAuthPrompt: (state, action) => {
      state.authPrompt = action.payload;
    }
  }
});

export const { setAuthData, setAuthPrompt } = User.actions;

export default User.reducer;
